<template>
    <div class="header py-0 shadow bg-white mt-3">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row py-3">
            <nav aria-label="breadcrumb" class="ml-2">
              <ol class="breadcrumb breadcrumb-links">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-link"></i></a>
                </li>
                <li class="breadcrumb-item"><a href="#"></a></li>
                All Loggings
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="card shadow w-100 p-4">
        <loader v-if="!data_loaded" />
        <data-table
          v-if="data_loaded"
          id="logtable"
          :options="dtb_options.extra_options"
          :actions="dtb_options.actions"
          :data="dtb_options.data"
          :columns="dtb_options.columns"
          :on_click="dtb_on_click"
        />
      </div>
    </div>
  </template>
  
  <script>
  import DataTable from "@/components/Datatable";
  import IndependentLoader from "../../components/IndependentDefaultLoader";
  import {
    getDocs,
    collection,
    doc,
    deleteDoc,
    where,
    query,
  } from "firebase/firestore";
  import { db } from "../../firebase";
  import { alert } from "../../utils/alertUtils";
  
  export default {
    name: "log",
    components: {
      "data-table": DataTable,
      loader: IndependentLoader,
    },
    data() {
      return {
        th: this,
        data_loaded: false,
        collections: [],
        dtb_options: {
          data: [],
          // actions: [
          //   {
          //     btnText: "Delete",
          //     btnClass: "btn btn-white text-danger btn-sm",
          //     iconClass: "fas fa-trash",
          //     btnFn: (uuid) => {
          //       this.delete_collection(uuid);
          //     },
          //     btnParamName: "uuid",
          //   },
          // ],
          columns: [
          {
              title: "Done At",
              data: "doneAt",
              render: (data) => {
                return data["doneAt"]
                  ? this.$date_format.fromFirestore(data["doneAt"])
                  : "N/A";
              },
            },
            {
              title: "Added At",
              data: "date_inserted",
              render: (data) => {
                return data["date_inserted"]
                  ? this.$date_format.fromFirestore(data["date_inserted"])
                  : "N/A";
              },
            },
            { title: "Reason", data: "reason" },
            { title: "Source", data: "source" },
            { title: "Action", data: "action" },
            { title: "TransactionType", data: "trans_type" },
            {
              title: "Amount",
              data: "amount",
              render: (data) => `${data["amount"]}`,
            },
          ],
          extra_options: {},
        },
      };
    },
    methods: {
      dtb_on_click: function (row) {
        var uuid = row.uuid;
        console.log(uuid);
        //  this.$router.push({ name: "Accomodation Details", params: { uuid } }); 
      },
      // delete_collection: function(uuid) {
      //   alert.promptDelete(this.implement_delete_collection(uuid));
      // },
  
      delete_collection: function (uuid) {
        const ref = doc(db, "log", uuid);
        deleteDoc(ref)
          .then(() => {
            this.dtb_options.data = [];
            this.fetch_logs();
            alert.success_center("Success");
          })
          .catch(() => {
            alert.error("Error occured in deleting");
          });
      },
  
      fetch_logs: function () {
        var q;
        if (this.$store.getters.getUserGroup === "superadmin") {
          q = query(collection(db, "logs"));
        } else {
          q = query(
            collection(db, "logs"),
            where("user_id", "==", this.$store.getters.getUserId)
          );
        }
  
        getDocs(q).then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.dtb_options.data = [];
            this.data_loaded = true;
          } else {
            querySnapshot.forEach((doc) => {
              let responseData = doc.data();
              responseData.uuid = doc.id;
              this.dtb_options.data.push(responseData);
              this.data_loaded = true;
            });
          }
        });
  
        // OLD WAY
  
        // fetch collections
        // getDocs(collection(db, "log"))
        //   .then((snapShots) => {
        //     snapShots.forEach((document) => {
        //       let collections = document.data();
        //       collections.uuid = document.id;
        //       this.dtb_options.data.push(collections);
        //     });
        //   })
        //   .then(() => {
        //     this.data_loaded = true;
        //   })
        //   .catch((e) => {
        //     console.log(`an error occured ${e}`);
        //     this.data_loaded = false;
        //   });
  
        // END OF OLD WAY
      },
    },
    created() {
      this.fetch_logs();
    },
  };
  </script>
  
  <style></style>
  